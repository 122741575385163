// @flow

import React from "react";
import {
    CreateInspectionLayout,
    InspectionSelectDates as SelectDates,
    Layout,
    withPrivateRoute,
} from "@containers";

/**
 * Home Page
 */
const NewInspection = (props: *) => (
    <Layout {...props}>
        <CreateInspectionLayout>
            <SelectDates />
        </CreateInspectionLayout>
    </Layout>
);

export default withPrivateRoute(NewInspection);
